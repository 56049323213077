import React , {useContext} from "react";
import { useHistory } from 'react-router-dom';

import { useForm } from '../../Shared/components/hooks/form-hook';
import Button from "../../Shared/components/FormElements/Button";
import Input from "../../Shared/components/FormElements/Input";
import { useHttpClient } from "../../Shared/components/hooks/http-hook";
import ErrorModal from "../../Shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../Shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../../Shared/context/auth-context";
import {
    VALIDATOR_MINLENGTH
  } from '../../Shared/components/util/validators.js';

import '../../servers/components/placeForm.css';


const ChangeServerDetails = (props) =>{
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();


    const [formState, inputHandler] = useForm(
        {
            serverName : {
            value: [props.name],
            isValid: false
          },
          ip : {
            value: [props.ip],
            isValid: false
          },
        },
        false
      );

    const placeUpdateSubmitHandler = async event => {
        event.preventDefault();
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}servers/${props.serverId}`,
            'PATCH',
            JSON.stringify({
              serverName: formState.inputs.serverName.value,
              ip: formState.inputs.ip.value
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          history.push('/allusers');
        } catch (err) {}
      };

    return (
      <React.Fragment>
        <ErrorModal error={error} onClear={clearError} />
        <form className="place-form" id={props.serverId} onSubmit={placeUpdateSubmitHandler}>
          {isLoading && (
            <div className="center">
              <LoadingSpinner />
            </div>
          )}
          <Input
            id="serverName"
            element="input"
            type="text"
            label="Set new server name"
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
            errorText="Please enter a valid server name."
            initialValue={props.name}
          />
        <Input
            id="ip"
            element="input"
            type="text"
            label="Set new server IP"
            validators={[VALIDATOR_MINLENGTH(6)]}
            onInput={inputHandler}
            errorText="Please enter a valid server IP."
            initialValue={props.ip}
          />
          <Button danger type="submit" >
            Update Server Details
          </Button>
        </form>
      </React.Fragment>
      );
}

  
  export default ChangeServerDetails;