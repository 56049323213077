import React,{ useEffect, useState, useContext} from "react";
import { useParams } from 'react-router-dom';

import UserServersList from "../Components/UserServersList";
import ErrorModal from "../../Shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../Shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../Shared/components/hooks/http-hook";
import { AuthContext } from "../../Shared/context/auth-context";

const UserDetails = () => {
  const auth = useContext(AuthContext);
  const [loadedServers, setLoadedPServers] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const userId = useParams().uid;

  const placeDeletedHandler = deletedServerId => {
    setLoadedPServers(prevServers =>
      prevServers.filter(server => server.id !== deletedServerId)
    );
  };

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}servers/${userId}`, undefined, undefined, {
            Authorization: 'Bearer ' + auth.token
          }
        );
        setLoadedPServers(responseData.servers);
      } catch (err) {}
    };
    fetchPlaces();
  }, [sendRequest, userId, auth.token]);

  return (
    <React.Fragment>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading && (
      <div className="center">
        <LoadingSpinner />
      </div>
    )}
    {!isLoading && loadedServers && <UserServersList items={loadedServers} onDeletePlace={placeDeletedHandler}/>}
    </ React.Fragment>
  )
};

export default UserDetails;
