import React from "react";



import MainHeader from "./MainHeader";
import NavLinks from "../Navigation/NavLinks"


const MainNavigation = (props) => {

  
    return (
      <React.Fragment>
        <MainHeader>
          {/* <button className="main-navigation__menu-btn" onClick={openDrawerHandler}>
            <span />
            <span />
            <span />
          </button> */}
          <h1 className="main-navigation__title">
            <img alt="logo" className="logo-img" src={"./dashboard-logo.png"} />
            
          </h1>
          <nav className="main-navigation__header-nav">
            <NavLinks />
          </nav>
  
        </MainHeader>
      </React.Fragment>
  
    );
  };

  export default MainNavigation;