import React, {useContext } from 'react';

import Card from '../../Shared/components/UIElements/Card';
import Input from '../../Shared/components/FormElements/Input';
import Button from '../../Shared/components/FormElements/Button';
import ErrorModal from '../../Shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../Shared/components/UIElements/LoadingSpinner';

import {
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE
} from '../../Shared/components/util/validators';
import { useForm } from '../../Shared/components/hooks/form-hook';
import {AuthContext} from '../../Shared/context/auth-context';
import { useHttpClient } from '../../Shared/components/hooks/http-hook';

import './Auth.css';

const Auth = () => {
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler] = useForm(
    {
      username: {
        value: '',
        isValid: false
      },
      password: {
        value: '',
        isValid: false
      }
    },
    false
  );


  const authSubmitHandler = async event => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}users/login`,
        'POST',
        JSON.stringify({
          username: formState.inputs.username.value,
          password: formState.inputs.password.value
        }),
        {
          'Content-Type': 'application/json'
        }
      );
      auth.login(responseData.userId, responseData.token, undefined ,responseData.isAdmin);
    } catch (err) {}
  };


  return (
    <React.Fragment>
    <ErrorModal error={error} onClear={clearError} />
    <Card className="authentication">
      {isLoading && <LoadingSpinner asOverlay />}
      <h2>Login Required</h2>
      <hr />
      <form onSubmit={authSubmitHandler}>
          <Input
            element="input"
            id="username"
            type="text"
            label="Username"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a name."
            onInput={inputHandler}
          />

        <Input
          element="input"
          id="password"
          type="password"
          label="Password"
          validators={[VALIDATOR_MINLENGTH(6)]}
          errorText="Please enter a valid password, at least 6 characters."
          onInput={inputHandler}
        />
        <Button type="submit" disabled={!formState.isValid}>
          LOGIN
        </Button>
      </form>
    </Card>
    </React.Fragment>

  );
};

export default Auth;