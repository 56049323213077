import React, { useContext } from "react";
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../../Shared/context/auth-context';

import './NavLinks.css';


const NavLinks = props => {
    const auth = useContext(AuthContext);

    return <ul className="nav-links">
        {auth.isLoggedIn && !auth.isAdmin && (
        <li>
            <NavLink to="/" exact>All Servers</NavLink>
        </li>
        )}
        {auth.isLoggedIn && !auth.isAdmin && (
        <li>
            <NavLink to="/addlink">Add Links</NavLink>
        </li>
        )}
        {auth.isAdmin && (
        <li>
            <NavLink to="/allusers">All USERS</NavLink>
        </li>
        )}
        {auth.isAdmin && (
        <li>
            <NavLink to="/createserver">New Server</NavLink>
        </li>
        )}
        {auth.isAdmin && (
        <li>
            <NavLink to="/newuser">New User</NavLink>
        </li>
        )}
        {!auth.isLoggedIn && (
        <li>
            <NavLink to="/auth"> Login</NavLink>
        </li>
        )}
        {auth.isLoggedIn && (
                    <li>
                    <button className="logout" onClick={auth.logout}>LOGOUT</button>
                  </li>
        )}
    </ul>
};

export default NavLinks;