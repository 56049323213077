import React from "react";
import { Link } from 'react-router-dom';

import "./ServerItem.css";
import Card from "../../Shared/components/UIElements/Card";
import ServerWhitelistForm from "./ServerWhitelistForm";

const ServerItem = (props) => {
  return (
    <li className="user-item">
      <Card className="user-item__content">
          <div className="user-item__info">
            <h2>{props.name}</h2>
            <ServerWhitelistForm serverId={props.id}/>
            <Link to={`/update/${props.id}`}>
              <i className="fa fa-gear" style={{fontSize: "60px"}} src='/aaaa'></i>
            </Link>
          </div>
      </Card>
    </li>
  );
};

export default ServerItem;