import React  from "react";
import { useHistory } from 'react-router-dom';

import "../../Shared/components/hooks/form-hook";
import { useForm } from '../../Shared/components/hooks/form-hook';
import Input from "../../Shared/components/FormElements/Input";
import Button from "../../Shared/components/FormElements/Button";
import ErrorModal from "../../Shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../Shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../../Shared/components/hooks/http-hook";
import { useContext } from "react";
import { AuthContext } from "../../Shared/context/auth-context";
import {
  VALIDATOR_REQUIRE
} from '../../Shared/components/util/validators.js';


import '../../servers/components/placeForm.css';
import './AddLink.css'



const AddLink = () => {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  
    const [formState, inputHandler] = useForm(
      {
        newlink: {
          value: '',
          isValid: false
        },
      },
      false
    );
    const addLinkSubmitHandler = async event => {
      event.preventDefault();
      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}users/newlink`,
          'POST',
          JSON.stringify({
            userid: auth.userId,
            link: formState.inputs.newlink.value
          }),
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token
          }
        );
        history.push('/');
      } catch (err) {}
    };

    return (
      <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
        <form className="place-form" onSubmit={addLinkSubmitHandler}>
        {isLoading && (
            <div className="center">
              <LoadingSpinner />
            </div>
          )}
          <Input
            id="newlink"
            element="input"
            type="text"
            label="Enter Your New Link"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={inputHandler}
            errorText="Please Enter Valid URL - http://example.com/"
          />
          <Button type="submit" disabled={!formState.isValid}>
            Add Link
          </Button>
        </form>
      </React.Fragment>
      );

};

export default AddLink;