import React, {useContext} from "react";

import { useForm } from '../../Shared/components/hooks/form-hook';
import Button from "../../Shared/components/FormElements/Button";
import Input from "../../Shared/components/FormElements/Input";
import { useHistory } from "react-router-dom";
import { useHttpClient } from "../../Shared/components/hooks/http-hook";
import { AuthContext } from "../../Shared/context/auth-context";
import {
    VALIDATOR_REQUIRE
  } from '../../Shared/components/util/validators.js';
import ErrorModal from "../../Shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../Shared/components/UIElements/LoadingSpinner";

import './placeForm.css';


const ServerWhitelistForm = (props) =>{
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [formState, inputHandler] = useForm(
        {
           whitelist: {
            value: '',
            isValid: false
          },
        },
        false
      );
    
    
    const history = useHistory();
    const placeUpdateSubmitHandler = async event => {
        event.preventDefault();
        console.log(formState.inputs);
        try {
          await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}servers//whitelist/${props.serverId}`,
            'PATCH',
            JSON.stringify({
              newIp: formState.inputs.whitelist.value
            }),
            {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + auth.token
            }
          );
          history.push('/');
        } catch (err) {}
      };

    return (
      <React.Fragment>
        <ErrorModal error={error} onClear={clearError} />
        <form className="place-form" onSubmit={placeUpdateSubmitHandler}>
        {isLoading && (
            <div className="center">
              <LoadingSpinner />
            </div>
          )}
          <Input
            id="whitelist"
            element="input"
            type="text"
            label="Whitelist IP"
            validators={[VALIDATOR_REQUIRE()]}
            onInput={inputHandler}
            errorText="Please enter a valid IP."
          />
          <Button type="submit" disabled={!formState.isValid}>
            Whitelist IP
          </Button>
        </form>
      </React.Fragment>        
      );
}

  
  export default ServerWhitelistForm;