import React, { useEffect, useState , useContext} from "react";

import ServerList from "../components/ServerList";
import {AuthContext} from '../../Shared/context/auth-context';
import { useHttpClient } from "../../Shared/components/hooks/http-hook";
import LoadingSpinner from "../../Shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../Shared/components/UIElements/ErrorModal";

const Servers = () => {
  const auth = useContext(AuthContext);
  const [loadedPlaces, setLoadedPlaces] = useState();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const {userId} = auth;

  useEffect(() => {
    const fetchPlaces = async () => {
      console.log(userId)
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}servers/${userId}`, undefined, undefined,{
            Authorization: 'Bearer ' + auth.token
          }
        );
        setLoadedPlaces(responseData.servers);
      } catch (err) {}
    };
    fetchPlaces();
  }, [sendRequest, userId, auth]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedPlaces && <ServerList items={loadedPlaces} />}
    </React.Fragment>
  )
};

export default Servers;
