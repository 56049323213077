import React from "react";

import UserServersItem from "./UserServersItem";
import Card from "../../Shared/components/UIElements/Card";

const UserServersList = (props) => {
    if (props.items.length === 0) {
      return (
        <div className="center">
          <Card>
            <h2>No Users found</h2>
          </Card>
        </div>
      );
    }
  
    return (
      <ul className="users-list">
        {props.items.map((server) => (
            <UserServersItem 
            key={server.id}
            id={server.id}
            name={server.serverName}
            ip={server.ip}
            onDelete={props.onDeletePlace}
            />
        ))}
      </ul>
    );
  };
  
  export default UserServersList;