import React from "react";
import ServerItem from "./ServerItem";

import Card from "../../Shared/components/UIElements/Card";

const ServerList = (props) => {
    if (props.items.length === 0) {
      return (
        <div className="center">
          <Card>
            <h2>You have No Servers</h2>
          </Card>
        </div>
      );
    }
  
    return (
      <ul className="users-list">
        {props.items.map((server) => (
          <ServerItem
            key={server.id}
            id={server.id}
            name={server.serverName}
          />
        ))}
      </ul>
    );
  };
  
  export default ServerList;