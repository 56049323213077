import React, {Suspense} from 'react';
import { BrowserRouter as Router,Redirect,Route, Switch } from 'react-router-dom'

import UserDetails from './Admin/pages/UserDetails';
import Servers from "./servers/pages/Servers"
// import AllUsers from './Admin/pages/AllUsers';
import MainNavigation from './Shared/components/Navigation/MainNavigation';
// import ServersUpdate from './servers/pages/ServersUpdate';
import AddLink from './Users/Components/AddLink'
// import CreateServer from './Admin/pages/createServer'
import Auth from './Users/pages/Auth';
// import NewUser from './Admin/pages/NewUser'
import { AuthContext } from './Shared/context/auth-context';
import useAuth from './Shared/components/hooks/auth-hook'
import LoadingSpinner from './Shared/components/UIElements/LoadingSpinner';

const CreateServer = React.lazy(() => import('./Admin/pages/createServer'));
const ServersUpdate = React.lazy(() => import('./servers/pages/ServersUpdate'));
const AllUsers = React.lazy(() => import('./Admin/pages/AllUsers'));
const NewUser = React.lazy(() => import('./Admin/pages/NewUser'));





const App = () => {
  const { token, login, logout, userId, isAdmin } = useAuth();

  let routes;

  if (isAdmin) {
    routes = (
      <Switch>
                <Route path="/update/:serverId" exact={true}>
                    <ServersUpdate />
                </Route>
                <Route path="/addlink" exact={true}>
                    <AddLink />
                </Route>
                <Route path="/auth" exact={true}>
                    <Auth />
                </Route>
                <Route path="/createserver" exact={true}>
                    <CreateServer />
                </Route>
                <Route path="/allusers" exact={true}>
                    <AllUsers />
                </Route>
                <Route path="/allusers/:uid" exact={true}>
                    <UserDetails />
                </Route>
                <Route path="/newuser" exact={true}>
                    <NewUser />
                </Route>
                <Redirect to="/allusers" />
          </Switch>
    );

  }
  else if (token) {
    routes = (
      <Switch>
                <Route path="/" exact={true}>
                    <Servers />
                </Route>
                <Route path="/update/:serverId" exact={true}>
                    <ServersUpdate />
                </Route>
                <Route path="/addlink" exact={true}>
                    <AddLink />
                </Route>
                <Route path="/newuser" exact={true}>
                    <NewUser />
                </Route>
                <Redirect to="/" />
          </Switch>
    );

  }
  else{
    routes = (
      <Switch>
                <Route path="/auth" exact={true}>
                    <Auth />
                </Route>
                <Redirect to="/auth" />
      </Switch>
    );
  }

  return  (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        isAdmin,
        login: login,
        logout: logout
      }}
    >
  <Router>
    <MainNavigation />
    <main><Suspense fallback={
    <div className='center'><LoadingSpinner/></div>
    }>
      {routes}
    </Suspense></main>
  </Router>
  </AuthContext.Provider>
  );
}

export default App;
