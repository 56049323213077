import React, {useState, useContext} from "react";


import Card from "../../Shared/components/UIElements/Card";
import Modal from "../../Shared/components/UIElements/Modal";
import Button from "../../Shared/components/FormElements/Button";
import ChangeServerDetails from "./ChangeServerDetails"
import ErrorModal from "../../Shared/components/UIElements/ErrorModal";
import { useHttpClient } from "../../Shared/components/hooks/http-hook";
import LoadingSpinner from "../../Shared/components/UIElements/LoadingSpinner";
import { AuthContext } from "../../Shared/context/auth-context";

import "./UserItem.css";

const UserServersItem = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };
  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}servers/${props.id}`,
        'DELETE',
        null,
        {
          Authorization: 'Bearer ' + auth.token
        }
      );
      props.onDelete(props.id);
    } catch (err) {}
  };

  return (
    <React.Fragment>
        <Modal
            show={showConfirmModal}
            onCancel={cancelDeleteHandler}
            header="Are you sure?"
            footerClass="place-item__modal-actions"
            footer={
              <React.Fragment>
                <Button inverse onClick={cancelDeleteHandler}>
                  CANCEL
                </Button>
                <Button danger onClick={confirmDeleteHandler}>
                  DELETE
                </Button>
              </React.Fragment>
            }
          >
        <p>
          Do you want to proceed and delete this server? Please note that it
          can't be undone there after.
        </p>
      </Modal>
    <ErrorModal error={error} onClear={clearError} />
    <li className="user-item">
      <Card className="user-item__content">
      {isLoading && <LoadingSpinner asOverlay />}
          <div className="user-item__info">
            <h2>{props.name} - {props.ip}</h2>
            <ChangeServerDetails serverId={props.id} name={props.name} ip={props.ip}/>
          </div>
          <div className="deletebtn">
            <Button danger onClick={showDeleteWarningHandler}>
                  DELETE
            </Button>
          </div>

      </Card>
    </li>
    </React.Fragment>
  );
};

export default UserServersItem;